window.Client = {

    init: function() {
window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow screen view: init', event.detail.screenName);
});
    },


    onRender: function(screenId) {
        
        
        const input = document.createElement('input');
input.type = 'text';
input.name = 'fullURL';
input.id = 'fullURL';
input.setAttribute('data-label', 'Full URL');
input.placeholder = "type your company name here";
input.style = "display:block;"
input.id = "company-input"

input.onchange = function(e){
    console.log(e, 222)
    const value = event.srcElement.value
                let headers = new Headers();
const url = 'https://corsproxy.io/?' + encodeURIComponent('https://app.fincompare.de/api/v1/company?q=' + value);

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
      
      fetch(url, {
          method: 'GET',
          headers: headers
      })
  .then(response => response.json())
  .then(data => {
      renderTable(data)

  })
  .catch(error => {
    console.error('Error:', error);
  });
    
    console.log('input change:', event.srcElement.value);  
};
        
        
        function renderTable(dataArray) {
  const table = document.createElement('table');
  const thead = document.createElement('thead');
  const tbody = document.createElement('tbody');

  // Create table header
  const headerRow = document.createElement('tr');
  Object.keys(dataArray[0]).forEach(property => {
    const th = document.createElement('th');
    th.textContent = property;
    headerRow.appendChild(th);
  });
  thead.appendChild(headerRow);

  // Create table rows
  dataArray.forEach(item => {
    const row = document.createElement('tr');
    Object.values(item).forEach(value => {
      const cell = document.createElement('td');
      cell.textContent = value;
      row.appendChild(cell);
    });
    tbody.appendChild(row);
  });

  table.appendChild(thead);
  table.appendChild(tbody);


}

const element = document.getElementById("id-d3fb9d36")

element.onchange = (e) => {
    console.log(e)
}

console.log(document.getElementById("id-d3fb9d36"), onchange)
// document.getElementsByTagName('form')[0].innerHTML = '<iframe height="800px" width="800px" src="https://fincompare.de/b2b/funnel-complete/?email=aS@gmial.com" title="W3Schools Free Online Web Tutorials"></iframe>'
        // Do something on screen render



    window.addEventListener('heyflow-change', (event) => {
        console.log('heyflow screen view: init', event);
    console.log('heyflow screen view: init', event.detail.screenName);

          const form = document.getElementsByTagName('form')[0];
          console.log(form)
    form.appendChild(input);


    })
    
 
        
  
        
}}